import PageContents from '@/types/PageContents';
import EndoVideo from './EndoLIF-Video.html';
import Endo from './EndoLIF.html';
import PerscusysVideo from './Percusys-Plus-Video.html';
import Perscusys from './Percusys-Plus.html';
import IntroVideo from './spinal-Intro-Video.html';
import Intro from './spinal-Intro-Text.html';

// const distanceX = 7.5;

const videoModalSize = {
  height: 'calc(315px + 4rem)',
  width: 'calc(560px + 4rem)',
};

const pageContent: PageContents[] = [
  {
    button: {
      top: '26%',
      left: '71%',
      type: 'video',
      content: '',
    },
    modal: {
      ...videoModalSize,
      content: IntroVideo,
    },
  },
  {
    button: {
      top: '26%',
      left: '75.5%',
      type: 'open',
      content: 'Intro',
    },
    modal: {
      content: Intro,
    },
  },

  {
    button: {
      top: '47%',
      left: '54%',
      type: 'video',
      content: '',
    },
    modal: {
      ...videoModalSize,
      content: PerscusysVideo,
    },
  },
  {
    button: {
      top: '47%',
      left: '58.5%',
      type: 'open',
      content: '',
    },
    modal: {
      content: Perscusys,
    },
  },

  {
    button: {
      top: '41%',
      left: '28%',
      type: 'video',
      content: '',
    },
    modal: {
      ...videoModalSize,
      content: EndoVideo,
    },
  },
  {
    button: {
      top: '41%',
      left: '32.5%',
      type: 'open',
      content: '',
    },
    modal: {
      content: Endo,
    },
  },
];

export default pageContent;
